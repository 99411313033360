<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container :title="$route.name" :exportInfo="currItems" :dates="[forms.begin, forms.end]">
      <template v-slot:bottom-header>
        <v-row class="mx-4">
          <v-col cols="12" md="3">
            <Date-Picker v-model="forms.begin" label="Fecha inicio" offYear offLimit />
          </v-col>
          <v-col cols="12" md="3">
            <Date-Picker v-model="forms.end" label="Fecha fin" offYear offLimit />
          </v-col>
          <v-col cols="12" md="1">
            <Basic-Btn text="Buscar" color="success" icon="mdi-magnify" @click="refresh()" :loading="loading" :disabled="!(forms.begin && forms.end)" />
          </v-col>
        </v-row>
      </template>
      <template v-slot:buttons>
        <Basic-Btn text="Agregar" color="primary" icon="mdi-plus" @click="dialogs.new = true" :loading="loading" />
      </template>
      <template v-slot:search>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </template>
      <v-data-table :headers="headers" :items="items" :loading="loading" :search="search" @current-items="getFiltered"
      :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }"
      :items-per-page="itemsPerPage" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.tiempo_retardo`]="{ item }">
          {{ item.tiempo_retardo ? item.tiempo_retardo : "" }}
        </template>
        <template v-slot:[`item.nombre`]="{ item }">
          {{ item.nombre + " " + item.apellido_paterno + " " + item.apellido_materno }}
        </template>
        <template v-slot:[`item.fecha`]="{ item }">
          {{ item.fecha ? dateFormat(item.fecha + " " + item.hora) : "" }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item)">mdi-pencil</v-icon></template
            ><span>Editar</span></v-tooltip
          >
          <v-tooltip right
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="remove(item.idevento, item.evento)">mdi-delete</v-icon></template
            ><span>Eliminar</span></v-tooltip
          >
        </template>
        <template v-slot:[`footer.page-text`]="props"> Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }} </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>
    <Dialog-Form :estate="dialogs.new" title="Nueva incidencia">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Guardar" color="success" icon="mdi-check" @click="loopAction('save')" :loading="loading" />
      </template>
      <v-form ref="formNew" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-autocomplete v-model="forms.new.usuario" :items="users" item-text="nombreCompleto" item-value="idusuario" label="Empleado" prepend-icon="mdi-account" persistent-hint no-data-text="No se encontraron resultados" dense outlined></v-autocomplete>
          </v-col>
          <v-col cols="12" xs="12">
            <v-autocomplete v-model="forms.new.tipo" :items="allSubtypes" item-text="nombre" item-value="nombre" @change="selectSubtype()" label="Subtipo de solicitud" prepend-icon="mdi-calendar-week-begin" persistent-hint no-data-text="No se encontraron resultados" dense outlined></v-autocomplete>
          </v-col>
          <v-col cols="6">
            <Hour-Picker v-model="forms.new.hora" label="Hora de inicio" hideDetails />
          </v-col>
          <v-col cols="6">
            <v-text-field v-model.number="forms.new.tiempo_retardo" label="Tiempo retardo(minutos)" prepend-icon="mdi-clock" type="number" :disabled="loading" dense outlined required></v-text-field>
          </v-col>
          <v-row v-if="inability">
            <v-col cols="6">
              <Date-Picker v-model="forms.new.dia" :label="inability ? 'A partir de' : 'Fecha'" offYear offLimit />
            </v-col>
            <v-col cols="6">
              <v-text-field v-model.number="repetition" label="Días" prepend-icon="mdi-calendar-clock" type="number" min="1" :disabled="loading" dense outlined required></v-text-field>
            </v-col>
            <v-col cols="12">
              <Date-Picker v-model="lastDay" label="Fecha fin" icon="mdi-calendar-alert" offYear offLimit disabled/>
            </v-col>
          </v-row>
          <v-row v-if="!inability">
            <v-col cols="12" xs="12">
              <v-autocomplete v-model="typeSelectDates" :items="typeSelectDatesArray" label="Dias solicitados" prepend-icon="mdi-calendar" persistent-hint no-data-text="No se encontraron resultados" hide-details dense outlined></v-autocomplete>
            </v-col>
            <v-col cols="12" xs="12" v-if="typeSelectDates == 'Fechas especificas'">
              <v-date-picker v-model="dates" locale="es" multiple no-title full-width></v-date-picker>
            </v-col>
            <v-col cols="6" xs="6" v-if="typeSelectDates == 'Días consecutivos'">
              <Date-Picker v-model="begin" label="Fecha inicio" offYear hideDetails offLimit />
            </v-col>
            <v-col cols="6" xs="6" v-if="typeSelectDates == 'Días consecutivos'">
              <Date-Picker v-model="end" label="Fecha fin" offYear hideDetails offLimit />
            </v-col>
          </v-row>
        </v-row>
      </v-form>
    </Dialog-Form>
    <Dialog-Form :estate="dialogs.update" title="Actualizar incidencia">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-check" @click="update()" :loading="loading" />
      </template>
      <v-form ref="formUpdate" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-autocomplete v-model="forms.update.usuario" :items="users" item-text="nombreCompleto" item-value="idusuario" label="Empleado" prepend-icon="mdi-account" persistent-hint no-data-text="No se encontraron resultados" dense outlined disabled></v-autocomplete>
          </v-col>
          <v-col cols="12" xs="12">
            <v-autocomplete v-model="forms.update.tipo" :items="allSubtypes" item-text="nombre" item-value="nombre" label="Subtipo de solicitud" prepend-icon="mdi-calendar-week-begin" persistent-hint no-data-text="No se encontraron resultados" dense outlined></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-dialog ref="dialogDate" v-model="modalUpdateDate" :return-value.sync="forms.update.dia" persistent width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="forms.update.dia" :label="!inability ? 'Fecha' : 'A partir de'" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" dense outlined></v-text-field>
              </template>
              <v-date-picker v-model="forms.update.dia" scrollable @change="$refs.dialogDate.save(forms.update.dia)" locale="es"></v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="6">
            <v-dialog ref="dialogHour" v-model="modalUpdateHour" :return-value.sync="forms.update.hora" persistent width="290px">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="forms.update.hora" label="Hora" prepend-icon="mdi-clock-outline" hide-details readonly v-bind="attrs" v-on="on" outlined dense></v-text-field>
              </template>
              <v-time-picker v-if="modalUpdateHour" v-model="forms.update.hora" format="ampm" full-width>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalUpdateHour = false"> Cancel </v-btn>
                <v-btn text color="primary" @click="$refs.dialogHour.save(forms.update.hora)"> OK </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model.number="forms.update.tiempo_retardo" label="Tiempo retardo(minutos)" prepend-icon="mdi-clock" type="number" :disabled="loading" dense outlined required></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <Delete-Message :estate="dialogs.remove" msg="¿Realmente desea eliminar la solicitud?">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="disabled" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Eliminar" color="error" icon="mdi-check" @click="remove()" :loading="loading" />
      </template>
    </Delete-Message>
    <Dialog-Form :estate="dialogs.vacation" title="Aviso">
      <template v-slot:buttons>
        <Basic-Btn text="Aceptar" color="success" icon="mdi-check" @click="dialogs.vacation = false" :loading="loading" />
      </template>
      <h3>Movimiento eliminado, favor de ir a Captura de Vacaciones para validar su saldo y realizar las acciones necesarias.</h3>
    </Dialog-Form>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import services from "@/utils/services";
import { Loader, BasicAlert, TableContainer, BasicBtn, DatePicker, DialogForm, DeleteMessage, HourPicker } from "@/components";
import moment from "moment-timezone";

export default {
  name: "employee-incidents",
  components: {
    Loader,
    BasicAlert,
    TableContainer,
    BasicBtn,
    DatePicker,
    DialogForm,
    DeleteMessage,
    HourPicker,
  },
  data: () => ({
    loading: false,
    inability: false,
    repetition: 1,
    deleted: 0,
    event: null,
    typeSelectDates: "",
    typeSelectDatesArray: ["Días consecutivos", "Fechas especificas"],
    begin: "",
    end: "",
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    search: "",
    dateSearch: null,
    today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    headers: [
      { text: "Numero de empleado", align: "left", sortable: true, value: "no_empleado" },
      { text: "Nombre", align: "left", sortable: true, value: "nombre" },
      { text: "", align: " d-none", sortable: true, value: "nombre_completo" },
      { text: "Evento", align: "left", sortable: true, value: "evento" },
      { text: "Departamento", align: "left", sortable: true, value: "departamento" },
      { text: "Fecha", align: "left", sortable: false, value: "fecha" },
      { text: "Retraso (En minutos)", align: "left", sortable: false, value: "tiempo_retardo" },
      { text: "Acciones", align: "left", sortable: false, value: "actions" },
    ],
    items: [],
    users: [],
    dates: [],
    currItems: [],
    itemsPerPage: 20,
    allSubtypes: [],
    dialogs: {
      new: false,
      update: false,
      remove: false,
      vacation: false
    },
    modalUpdateDate: false,
    modalUpdateHour: false,
    forms: {
      begin: "",
      end: "",
      new: {
        usuario: "",
        tipo: "",
        fecha: "",
        tiempo_retardo: 0,
        dia: "",
        hora: "08:00",
      },
      update: {
        idevento: "",
        usuario: "",
        tipo: "",
        fecha: "",
        tiempo_retardo: "",
        dia: "",
        hora: "",
      },
    },
  }),
  computed: {
    lastDay() {
      let last = '';
      if (this.forms.new.dia) {
        last = moment(this.forms.new.dia).add(this.repetition - 1, "days").format("YYYY-MM-DD");
      }
      return last;
    },
  },
  mounted() {
    this.getUser();
    this.getSubtypes();
  },
  watch: {
    search(val) {
      this.itemsPerPage = (val) ? -1 : 20
    }
  },
  methods: {
    refresh() {
      this.loading = true;
      let request = "/calendar/full?fecha_inicio=" + this.forms.begin + "&fecha_fin=" + this.forms.end;
      this.axios
        .get(services.routes.event + request)
        .then((response) => {
          this.items = response.data.data;
          for (let i = 0; i < this.items.length; i++) {
            this.items[i].nombre_completo = this.items[i].nombre + " " + this.items[i].apellido_paterno + " " + this.items[i].apellido_materno
          }
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
          this.alert.active = true;
          this.alert.color = "success";
          this.alert.msg = "Registros obtenidos correctamente.";
        });
    },
    async getSubtypes() {
      this.loading = true;
      this.axios
        .get(services.routes.requestSubtype)
        .then((response) => {
          this.allSubtypes = response.data.data;
          for (let i = 0; i < this.allSubtypes.length; i++) {
            if (this.allSubtypes[i].nombre === 'VACACIONES') {
              this.allSubtypes.splice(i, 1);
              i--;
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getUser() {
      this.loading = true;
      this.axios
        .get(services.routes.user)
        .then((response) => {
          this.users = response.data.data;
          for (let i = 0; i < this.users.length; i++) {
            if (this.users[i].fecha_borrado) {
              this.users.splice(i, 1);
              i--;
            }
          }
          for (let i = 0; i < this.users.length; i++) {
            this.users[i].nombreCompleto =
              (this.users[i].detalles_usuarios[0].no_empleado ? this.users[i].detalles_usuarios[0].no_empleado : "") +
              (this.users[i].detalles_usuarios[0].nombre ? " " + this.users[i].detalles_usuarios[0].nombre : "") +
              (this.users[i].detalles_usuarios[0].apellido_paterno ? " " + this.users[i].detalles_usuarios[0].apellido_paterno : "") +
              (this.users[i].detalles_usuarios[0].apellido_materno ? " " + this.users[i].detalles_usuarios[0].apellido_materno : "");
          }
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      if (this.dialogs.new) {
        for (let key in this.forms.new) {
          if (key == "hora") {
            this.forms.new[key] = "08:00";
          } else if (key == "tiempo_retardo") {
            this.forms.new[key] = 0;
          } else {
            this.forms.new[key] = "";
          }
        }
        this.dialogs.new = false;
      } else if (this.dialogs.update) {
        this.dialogs.update = false;
      } else if (this.dialogs.remove) {
        this.dialogs.remove = false;
      }
      this.repetition = 0;
    },
    save(repetition) {
      if (this.$refs.formNew.validate()) {
        let data = JSON.parse(JSON.stringify(this.forms.new));
        data.fecha = moment.utc(moment(moment(repetition).format("YYYY-MM-DD") + " " + data.hora));
        if(this.inability){
          data.fecha_inicio = data.dia
          data.fecha_fin = this.lastDay;
        }
        this.axios
          .post(services.routes.catalog + "/event", { data })
          .then((response) => {
            this.axios.post(services.routes.eventDeleteFoul, { data })
            if (data.tipo.toUpperCase() === 'VACACIONES') {
              let _data = {
                idusuario: data.usuario,
                fecha_inicio: repetition,
                fecha_fin: repetition,
                dias: '1',
                antiguedad: null,
                tipo: 'BAJA',
                comentario: 'INCIDENCIAS DE EMPLEADOS',
                flag: true
              }
              this.axios.post(services.routes.vacations, { data: _data })
            }
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {});
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
    view(data) {
      this.forms.update = JSON.parse(JSON.stringify(data));
      this.forms.update.dia = data.fecha;
      this.forms.update.hora = data.hora;
      this.forms.update.usuario = data.idusuario;
      this.forms.update.tipo = data.evento;
      this.dialogs.update = true;
    },
    update() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.update));
        data.fecha = moment.utc(moment(data.dia + " " + data.hora));
        this.axios
          .put(services.routes.catalog + "/event", { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Incidencia actualizada exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
    remove(id, event) {
      if (!id) {
        this.loading = true;
        this.axios
          .delete(services.routes.catalog + "/event", { data: { data: { idevento: this.deleted } } })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Incidencia eliminada exitosamente";
            if (this.event === 'VACACIONES') {
              this.dialogs.vacation = true
            }
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.deleted = id;
        this.event = event;
        this.dialogs.remove = true;
      }
    },
    selectSubtype() {
      let auxData = this.forms.new.tipo;

      let aux = _.filter(this.allSubtypes, function (o) {
        return auxData == o.nombre;
      });
      if (aux[0].tipo_solicitud.nombre == "INCAPACIDAD") {
        this.inability = true;
        if (aux[0].nombre == "INCAPACIDAD POR MATERNIDAD") {
          this.repetition = 84;
        }
      } else {
        this.inability = false;
      }
      this.flowSubtype = aux[0].flujo_autorizaciones.data.flujo_aprobacion;
    },
    loopAction(action) {
      this.loading = true
      if (this.inability) {
        let data = JSON.parse(JSON.stringify(this.forms.new));
        let sendData = "";
        for (let i = 0; i < this.repetition; i++) {
          sendData = moment(data.dia).add(i, "days").format("YYYY-MM-DD");
          this[action](sendData);
        }
      } else {
        if (this.typeSelectDates == "Fechas especificas") {
          for (let date of this.dates) {
            this[action](date);
          }
        } else if (this.typeSelectDates == "Días consecutivos") {
          for (let m = moment(this.begin); m.diff(this.end, "days") <= 0; m.add(1, "days")) {
            this[action](m.format("YYYY-MM-DD"));
          }
        }
      }
      setTimeout(() => {
        this.loading = false
        this.cancel();
        this.refresh();
        this.alert.active = true;
        this.alert.color = "success";
        this.alert.msg = "Incidente creado exitosamente";
      }, 3000);
    },
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY h:mm:ss A");
    },
    getFiltered(values) {
      this.currItems = values
    }
  },
};
</script>

<style scoped>
</style>
